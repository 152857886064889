import { Component, OnDestroy } from '@angular/core';
import { Logger } from '@core/services/logger.service';
import { VersionService } from '@core/services/version.service';
import { NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppInfo, UserProfileData } from '../../../@core/core.types';
import { UserProfileState } from '../../../@core/store/user-profile/user-profile.state';
import { LayoutService } from '../../../@core/utils';
import { getAppInfoDisplayString, Util } from '../../../@core/utils/core.util';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnDestroy {
  userPictureOnly = true;
  user$: Observable<UserProfileData>;
  customLogoPath = '';
  appInfo: AppInfo;
  checkVersion$: Observable<string>;
  userMenu$: Observable<NbMenuItem[]>;
  private readonly log = new Logger(this.constructor.name);
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private layoutService: LayoutService,
    private store: Store,
    private translate: TranslateService,
    private versionService: VersionService
  ) {
    this.customLogoPath = Util.loadLogo();
    this.appInfo = this.versionService.currentAppInfo;
    this.checkVersion$ = this.versionService.checkVersion$;
    this.user$ = this.store.select(UserProfileState.getUserProfile);
    this.userMenu$ = combineLatest([this.user$, this.checkVersion$]).pipe(
      map(([user, update]) => {
        const userMenu: NbMenuItem[] = [
          { title: user.email },
          {
            title: this.translate.instant('header.userMenu.logOut'),
            link: '/auth/logout',
          },
        ];
        if (update) {
          userMenu.push({
            title: `${getAppInfoDisplayString(
              this.versionService.currentAppInfo
            )} - update ${update}`,
            url: window.location.href,
          });
        } else {
          userMenu.push({
            title: getAppInfoDisplayString(this.versionService.currentAppInfo),
          });
        }
        return userMenu;
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  refresh() {
    this.log.debug('refresh');
    this.versionService.update();
  }
}
