import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { UserProfileData } from '../../core.types';
import { Logger } from '../../services/logger.service';
import { UserProfileService } from '../../services/user-profile.service';
import {
  ClearUserProfileAction,
  LoadUserProfileAction,
} from './user-profile.actions';

@State<UserProfileData>({
  name: 'userProfile',
  defaults: null,
})
@Injectable()
export class UserProfileState implements NgxsOnInit {
  private readonly logger = new Logger(this.constructor.name);
  constructor(private userProfileService: UserProfileService) {}

  @Selector()
  static getUserProfile(state: UserProfileData) {
    return state;
  }

  @Action(LoadUserProfileAction)
  loadUserProfile({ patchState }: StateContext<UserProfileData>) {
    this.logger.debug('LoadUserProfileAction');
    this.userProfileService
      .getUserProfileData()
      .subscribe((value) => patchState(value));
  }

  @Action(ClearUserProfileAction)
  clearUserProfile({ patchState }: StateContext<UserProfileData>) {
    this.logger.debug('ClearUserProfileAction');
    patchState(null);
  }

  ngxsOnInit() {
    // this.authService.getAccessToken$().subscribe((token) => this.store.dispatch(token ? new LoadUserProfileAction() : new ClearUserProfileAction()));
  }
}
