import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { PartnerFindByIdComponent } from '@app/modules/partners/components/partner-find-by-id/partner-find-by-id.component';
import { ProductInventoryItemSearchComponent } from '@app/modules/service-data/components/product-inventory-item/product-inventory-item-search/product-inventory-item-search.component';
import { SampleSearchComponent } from '@app/modules/service-data/components/sample/sample-search/sample-search.component';
import { ServiceDataSearchComponent } from '@app/modules/service-data/components/service-data/service-data-search/service-data-search.component';
import { AuthenticationService } from '@core/services/authentication.service';
import { NbDialogService, NbIconModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';

enum SearchOption {
  ProductInventoryItem = 'Product inventory item',
  Sample = 'Sample',
  ServiceData = 'Service data',
  Partner = 'Partner',
}

@Component({
  standalone: true,
  selector: 'app-search-input',
  styleUrls: ['./search-input.component.scss'],
  templateUrl: './search-input.component.html',
  imports: [NbIconModule, TranslateModule, MatMenuModule, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit {
  @ViewChild('input', { static: true }) input: ElementRef;

  protected searchOptions$: Observable<SearchOption[]>;

  constructor(
    private nbDialogService: NbDialogService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    SearchOption.ProductInventoryItem,
      SearchOption.Sample,
      SearchOption.ServiceData,
      SearchOption.Partner,
      (this.searchOptions$ = combineLatest([
        this.authService.hasRequiredPermission$('advancedSearch.pii'),
        this.authService.hasRequiredPermission$('advancedSearch.samples'),
        this.authService.hasRequiredPermission$('advancedSearch.serviceData'),
        this.authService.hasRequiredPermission$('advancedSearch.partners'),
      ]).pipe(
        map(([pii, sample, serviceData, partner]) => {
          const searchOptions = [];

          if (pii) {
            searchOptions.push(SearchOption.ProductInventoryItem);
          }
          if (sample) {
            searchOptions.push(SearchOption.Sample);
          }
          if (serviceData) {
            searchOptions.push(SearchOption.ServiceData);
          }
          if (partner) {
            searchOptions.push(SearchOption.Partner);
          }

          return searchOptions;
        })
      ));
  }

  protected openSearchDialog(option: SearchOption) {
    switch (option) {
      case SearchOption.ProductInventoryItem: {
        this.nbDialogService.open(ProductInventoryItemSearchComponent);
        break;
      }
      case SearchOption.Sample: {
        this.nbDialogService.open(SampleSearchComponent);
        break;
      }
      case SearchOption.ServiceData: {
        this.nbDialogService.open(ServiceDataSearchComponent);
        break;
      }
      case SearchOption.Partner: {
        this.nbDialogService.open(PartnerFindByIdComponent);
        break;
      }
    }
  }
}
