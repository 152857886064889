<nb-card style>
  <nb-card-header class="position-relative">
    <p class="mb-0" translate="partnersPage.searchById"></p>
    <nb-icon (click)="dismiss()" icon="close"></nb-icon>
  </nb-card-header>
  <nb-card-body>
    <div class="row" [formGroup]="form">
      <div class="col-12">
        <div class="row align-items-center mb-3">
          <label class="col-3 label">Partner ID:</label>
          <div class="col-9">
            <input
              formControlName="ids"
              nbInput
              fullWidth
              placeholder="Enter partner ID"
              type="text"
            />
          </div>
        </div>
        <div class="row align-items-center mb-3">
          <label class="col-3 label">Subpartner ID:</label>
          <div class="col-9">
            <input
              formControlName="subPartnerId"
              nbInput
              fullWidth
              placeholder="Enter subpartner ID"
              type="text"
            />
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 d-flex flex-row-reverse">
        <button
          nbButton
          [disabled]="form.invalid || loading"
          status="primary"
          (click)="search()"
        >
          Search
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
