<i
  class="control-icon ion ion-ios-search"
  size="small"
  [matMenuTriggerFor]="submenu"
>
  <nb-icon icon="search-outline"></nb-icon>
</i>

<mat-menu #submenu="matMenu">
  @for (option of searchOptions$ | async; track option) {
    <button mat-menu-item size="small" (click)="openSearchDialog(option)">
      {{ option }}
    </button>
  }
</mat-menu>
