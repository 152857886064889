import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { getAppInfoDisplayString } from '@core/utils/core.util';
import currentAppInfo from '@root/assets/app-info.json';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import { ConfirmCloseReason } from '@shared/shared.types';
import moment from 'moment';
import { Observable, of, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { AppInfo } from '../core.types';
import { Logger } from './logger.service';

@Injectable()
export class VersionService {
  currentAppInfo = currentAppInfo;
  checkVersion$: Observable<string>;
  windowActive = false;

  private readonly logger = new Logger(this.constructor.name);

  constructor(
    private httpClient: HttpClient,
    private confirmService: ConfirmService,
    private router: Router
  ) {
    this.checkVersion$ = timer(0, 300000).pipe(
      switchMap(() => this.checkVersion()),
      distinctUntilChanged(),
      shareReplay(1)
    );
    this.checkVersion$.subscribe(async (versionUpdate) => {
      this.logger.debug('notify user for pending update');
      if (versionUpdate && !this.windowActive) {
        this.windowActive = true;
        const response = await this.showUpdateAlertDialog();
        if (response) {
          this.update();
        } else {
          this.router.events
            .pipe(first((e) => e instanceof NavigationEnd))
            .subscribe(() => this.update());
        }
        this.windowActive = false;
      }
    });
  }

  update() {
    this.logger.debug('update');
    window.location.reload();
  }

  private checkVersion(): Observable<string> {
    this.logger.debug('check version');
    return this.httpClient
      .get<AppInfo>(`/assets/app-info.json?timestamp=${moment().valueOf()}`)
      .pipe(
        catchError(() => of(null)),
        map((serverAppInfo: AppInfo) => {
          const serverVersionDisplayString = getAppInfoDisplayString(
            serverAppInfo
          );
          this.logger.debug(
            'current version',
            getAppInfoDisplayString(this.currentAppInfo),
            'server version',
            serverVersionDisplayString
          );
          if (
            serverAppInfo &&
            this.currentAppInfo &&
            (this.currentAppInfo.tag !== serverAppInfo.tag ||
              serverAppInfo.hash !== this.currentAppInfo.hash)
          ) {
            return serverVersionDisplayString;
          }
          return '';
        })
      );
  }

  private async showUpdateAlertDialog(): Promise<boolean> {
    this.logger.debug('show warning dialog');
    const title = 'Update available';
    const message =
      'Application was updated. Would you like to refresh your browser window to receive the latest updates now?';
    const confirmResponse = await this.confirmService
      .confirm({
        title,
        message,
      })
      .toPromise();
    if (
      confirmResponse &&
      confirmResponse.closeReason === ConfirmCloseReason.Yes
    ) {
      return true;
    }
    return false;
  }
}
