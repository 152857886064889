<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img
        class="brand-logo d-block mx-auto"
        height="28"
        alt="GenePlanet"
        [src]="customLogoPath"
      />
    </a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container" *ngIf="user$ | async as user">
  <nb-actions size="small">
    <nb-action *appRequirePermission="'advancedSearch'" class="control-item">
      <app-search-input></app-search-input>
    </nb-action>

    <!--
      <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *ngIf="userMenu$ | async as userMenu">
      <nb-icon
        class="danger"
        icon="alert-triangle"
        *ngIf="checkVersion$ | async"
      ></nb-icon>
      <nb-user
        [nbContextMenu]="userMenu"
        translate
        [onlyPicture]="userPictureOnly"
        [name]="user?.email"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
